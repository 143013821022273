import RoadmapContent from '@components/molecules/RoadmapContent'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export default function Roadmap ({ setDiscoverMenuItem } : any) {
  const { ref, inView } = useInView({threshold: 0.2})
  const { t, lang } = useTranslation('common')

  useEffect(() => {
    if (inView) {
      setDiscoverMenuItem('roadmap')
    }
  }, [inView])

  return (
    <section className={`section ${!inView && 'opacity-0'}`} id='roadmap' ref={ref}>
      <div className='w-full py-[60px] md:pt-[80px] px-4'>
        <div className={`${inView && 'section-title'} ml-0 md:ml-[25%]`}>
          <h6>/ {t('roadmap.title')}</h6>
        </div>
        <div
          className={`${!inView && 'opacity-0'} ${inView && 'opacity-animation-long'}`}
          style={{
            transition: 'all 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s',
            animationDuration: '1s'
          }}
        >
          <RoadmapContent />
        </div>
      </div>
    </section>
  )
}
