import Image from 'next/image';
import styles from './styles.module.css';
import { useEffect, useState } from 'react';

export default function RoadmapCell ({step, index, activeCell, setActiveCell} : {step: {title: string, description: string}, index: number, activeCell: number, setActiveCell: (value: number) => void}) {
  const [openFlight, setOpenFlight] = useState(false)

  useEffect(() => {
    activeCell !== index && setOpenFlight(false)  
  }, [activeCell])

  const handleOpenFlight = (flightIndex: number) => {
    setActiveCell(index)
    flightIndex === index % 2 && setOpenFlight(!openFlight)
  }

  return (
    <div className={`flex items-center mr-5 w-[240px] ${styles.container}`}>
      <Image loading='lazy' src='/roadmap-left.svg' alt='roadmap element' width={82} height={172} />
      <div className='flex flex-col items-center min-w-[120px] h-full relative'>
        {
          index % 2 
            ?  <p 
                onClick={() => handleOpenFlight(1)}
                className={`
                  font-[BeVietnam] font-semibold text-[24px] leading-[40px] text-white absolute whitespace-pre cursor-pointer
                  bottom-0 left-[100%] ${openFlight  ? `translate-y-[75px] duration-300 ` : `translate-y-0 duration-300 `}
                `}
              >
                {step.title}
              </p>
            :  <p 
                onClick={() => handleOpenFlight(0)}
                className={`
                  font-[BeVietnam] font-semibold text-[24px] leading-[40px] text-white absolute whitespace-pre cursor-pointer
                  top-0 left-[70px] ${openFlight ? `-translate-y-[100px] duration-300 ` : `translate-y-0 duration-300 `}
                `}
              >
                {step.title}
              </p>
        }
        {
          openFlight && 
            <p 
              className={`
                font-[BeVietnam] text-[16px] leading-[28px] text-white whitespace-pre absolute
                ${index % 2 ? '-bottom-[100px] left-[100%]' : '-top-[52px] left-[70px]'}
                ${styles.opacity}
              `}
            >
              {step.description}
            </p>
        }
        <svg
          width="84" 
          height="174" 
          viewBox="0 0 84 174" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          className={`
            ${index % 2 === 0 && styles.outline} ${index % 2 === 0 && 'cursor-pointer'} 
            ${openFlight && index % 2 === 0 ? `-translate-y-[55%] duration-300 ${styles.fill}` : `-translate-y-0 duration-300 ${styles.empty}`}
            mb-[20px] -ml-[85%]
          `}
          onClick={() => handleOpenFlight(0)}
        >
          <path d="M16.6278 1.18475L16.6278 1.18485L16.6374 1.18481L83.1376 0.945164L83.1375 157.385L83.1377 157.393C83.1686 159.366 82.8085 161.326 82.0782 163.16C81.3479 164.993 80.2618 166.664 78.8826 168.076C77.5034 169.488 75.8584 170.613 74.0424 171.385C72.227 172.158 70.2766 172.564 68.3037 172.579C68.3031 172.579 68.3024 172.579 68.3018 172.579L50.9384 172.579V122.737V122.237H50.4384H1.44141V16.1375V16.1375C1.44116 14.154 1.83554 12.1904 2.60155 10.3608C3.36756 8.53129 4.48991 6.87241 5.90323 5.48083C7.31656 4.08924 8.99263 2.99275 10.8338 2.25519C12.675 1.51763 14.6446 1.15376 16.6278 1.18475ZM51.3005 82.3024H51.3006V82.2945V33.6426V33.1426H50.8006H36.6758C36.048 33.1426 35.4051 33.3153 34.9045 33.6491C34.3991 33.986 34.0028 34.5178 34.0028 35.212V89.7794V90.2794H34.5028H43.1949C45.3225 90.2797 47.3649 89.4434 48.8814 87.951C50.3979 86.4586 51.2667 84.4298 51.3005 82.3024Z" stroke="#E2E2E2"/>
        </svg>
        <svg 
          width="83" 
          height="173" 
          viewBox="0 0 83 173" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg" 
          className={`
            ${index % 2 && styles.outline} ${index % 2 && 'cursor-pointer'} -mt-[10px]
            ${openFlight && index % 2 ? `translate-y-[55%] duration-300 ${styles.fill}` : `translate-y-0 duration-300 ${styles.empty}`}
          `}
          onClick={() => handleOpenFlight(1)}
        >
          <path d="M32.8218 50.5745V51.0745H33.3218H53.845H82.1981V157.536C82.1981 161.47 80.6354 165.243 77.8539 168.024C75.0723 170.806 71.2998 172.368 67.3661 172.368H0.501894V15.9266H0.501955L0.501832 15.9188C0.470936 13.9454 0.831018 11.9854 1.56131 10.1518C2.29161 8.31826 3.37767 6.64742 4.75686 5.23565C6.13605 3.82388 7.78108 2.69912 9.59709 1.92622C11.4125 1.15357 13.3629 0.747846 15.3358 0.732422H32.8218V50.5745ZM32.7011 139.669V140.169H33.2011H47.3258C47.9537 140.169 48.5966 139.996 49.0972 139.663C49.6025 139.326 49.9989 138.794 49.9989 138.1V83.8944V83.3944H49.4989H40.8068C38.6791 83.3941 36.6367 84.2304 35.1203 85.7228C33.6038 87.2152 32.7349 89.244 32.7012 91.3714H32.7011V91.3793V139.669Z" stroke="#E2E2E2"/>
        </svg>
      </div>
      <Image loading='lazy' src='/roadmap-right.svg' width={82} height={172} alt='roadmap element' className='-ml-[20%]' />
    </div>
  )
}
