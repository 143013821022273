import { useRef, useState } from 'react'
import Image from 'next/image'
import RoadmapCell from '@components/atoms/RoadmapCell'
import { useIsTablet } from '@hooks/useIsTablet'
import { useIsMobile } from '@hooks/useIsMobile'
import RoadmapCellMobile from '@components/atoms/RoadmapCellMobile'
import useTranslation from 'next-translate/useTranslation'

export default function RoadmapContent () {
  const { t, lang } = useTranslation('common')

  const steps = [
    {
      title: t('roadmap.flight1.title'),
      description: t('roadmap.flight1.description')
    },
    {
      title: t('roadmap.flight2.title'),
      description: t('roadmap.flight2.description')
    },
    {
      title: t('roadmap.flight3.title'),
      description: t('roadmap.flight3.description')
    },
    {
      title: t('roadmap.flight4.title'),
      description: t('roadmap.flight4.description')
    },
    {
      title: t('roadmap.flight5.title'),
      description: t('roadmap.flight5.description')
    },
    {
      title: t('roadmap.flight6.title'),
      description: t('roadmap.flight6.description')
    },
    {
      title: t('roadmap.flight7.title'),
      description: t('roadmap.flight7.description')
    }
  ]
  const [displayLeft, setDisplayLeft] = useState(false);
  const [displayRight, setDisplayRight] = useState(true);
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const roadmapScrollContainer = useRef<any>();
  const [activeCell, setActiveCell] = useState(-1);

  const smoothScroll = (direction: string) => {
    let times = 0;

    const interval = setInterval(() => {
        times++;
        if(times === 15)
          clearInterval(interval)

        direction === 'left' ? roadmapScrollContainer.current.scrollLeft -= 8 : roadmapScrollContainer.current.scrollLeft += 8
        roadmapScrollContainer.current.scrollLeft > 0 ? setDisplayLeft(true) : setDisplayLeft(false)
        roadmapScrollContainer.current.scrollLeft > 510 ? setDisplayRight(false) : setDisplayRight(true)
      }, 25);
      return () => clearInterval(interval);
  }

  const handleScroll = (direction: string) => {
    if(!roadmapScrollContainer.current)
      return 
    direction === 'left' ? smoothScroll('left') : smoothScroll('right');
  }

  const handleScrollContainer = (e: any) => {
    e.target.scrollLeft > 0 ? setDisplayLeft(true) : setDisplayLeft(false)
    e.target.scrollLeft > 510 ? setDisplayRight(false) : setDisplayRight(true)
  }

  return (
    <div className='relative ml-0 md:ml-[15%]'>
      {
        displayLeft &&
        <div
          className='absolute left-4 h-full w-[60px] md:w-[145px] top-0 z-10'
          style={{ background: 'linear-gradient(270deg, rgba(17, 17, 17, 0) 42.05%, rgba(17, 17, 17, 0.9) 71.59%)' }}
        ></div>
      }

      <div 
        ref={roadmapScrollContainer} 
        onScroll={() => handleScrollContainer(event)}
        className='flex max-w-full overflow-x-scroll items-center ml-4 py-32 no-scrollbar'
      >
        {
          steps.map((step, index: number) => {
            return (
              <div key={index} className='w-fit'>
                {
                  isMobile 
                    ? <RoadmapCellMobile step={step} index={index} activeCell={activeCell} setActiveCell={setActiveCell} /> 
                    : <RoadmapCell step={step} index={index} activeCell={activeCell} setActiveCell={setActiveCell} />
                }
              </div>
            )
          })
        }
      </div>

      {
        displayRight &&
        <div
          className='absolute -right-1 h-full w-[60px] md:w-[145px] top-0 z-10'
          style={{ background: 'linear-gradient(90deg, rgba(17, 17, 17, 0) 42.05%, rgba(17, 17, 17, 0.9) 71.59%)' }}
        ></div>
      }

      {
        !isTablet &&
        <div className='flex justify-center items-center w-full mt-16 gap-6'>
          <button 
            onClick={() => handleScroll('left')} 
            style={{ visibility: displayLeft ? 'visible' : 'hidden'}}
            className={`${!displayRight && displayLeft && 'float-left'}`}
          >
            <Image loading='lazy' src='/scroll-left.svg' alt='scroll left arrow' width={70} height={2} />
          </button>
          <button 
            onClick={() => handleScroll('right')} 
            style={{ visibility: displayRight ? 'visible' : 'hidden'}}
            className={`${ displayRight && !displayLeft && 'float-right'}`}
          >
            <Image loading='lazy' src='/scroll-right.svg' alt='scroll right arrow' width={70} height={2} />
          </button>
        </div>
      }
    </div>
  )
}
