import Image from 'next/image';
import styles from './styles.module.css';
import { useEffect, useState } from 'react';

export default function RoadmapCellMobile ({step, index, activeCell, setActiveCell} : {step: {title: string, description: string}, index: number, activeCell: number, setActiveCell: (value: number) => void}) {
  const [openFlight, setOpenFlight] = useState(false)

  useEffect(() => {
    activeCell !== index && setOpenFlight(false)  
  }, [activeCell])

  const handleOpenFlight = (flightIndex: number) => {
    setActiveCell(index)
    flightIndex === index % 2 && setOpenFlight(!openFlight)
  }

  return (
    <div className={`flex items-center mr-5 w-[180px] ${styles.container}`}>
      <Image loading='lazy' src='/roadmap-left.svg' alt='roadmap element' width={60} height={124} />
      <div className='flex flex-col items-center min-w-[120px] h-full relative'>
        {
          index % 2 
            ?  <p 
                onClick={() => handleOpenFlight(1)}
                className={`
                  font-[BeVietnam] font-semibold text-[24px] leading-[40px] text-white absolute whitespace-pre cursor-pointer
                  bottom-0 left-[100px] ${openFlight  ? `translate-y-[48px] duration-300 ` : `translate-y-0 duration-300 `}
                `}
              >
                {step.title}
              </p>
            :  <p 
                onClick={() => handleOpenFlight(0)}
                className={`
                  font-[BeVietnam] font-semibold text-[24px] leading-[40px] text-white absolute whitespace-pre cursor-pointer
                  top-0 left-[64px] ${openFlight ? `-translate-y-[80px] duration-300 ` : `translate-y-0 duration-300 `}
                `}
              >
                {step.title}
              </p>
        }
        {
          openFlight && 
            <p 
              className={`
                font-[BeVietnam] text-[16px] leading-[28px] text-white whitespace-pre absolute
                ${index % 2 ? '-bottom-[80px] left-[100px]' : '-top-[40px] left-[64px]'}
                ${styles.opacity}
              `}
            >
              {step.description}
            </p>
        }
        <svg 
          width="60" 
          height="125" 
          viewBox="0 0 60 125" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          className={`
            ${index % 2 === 0 && styles.outline} ${index % 2 === 0 && 'cursor-pointer'} 
            ${openFlight && index % 2 === 0 ? `-translate-y-[60%] duration-300 ${styles.fill}` : `-translate-y-0 duration-300 ${styles.empty}`}
            mb-[20px] -ml-[85%]
          `}
          onClick={() => handleOpenFlight(0)}
        >
          <path d="M11.3805 0.906263L11.3805 0.906329L11.3875 0.906304L59.3025 0.733633L59.3025 113.452L59.3026 113.458C59.3248 114.88 59.0654 116.292 58.5392 117.613C58.013 118.934 57.2305 120.138 56.2367 121.156C55.243 122.173 54.0577 122.983 52.7492 123.54C51.441 124.097 50.0356 124.389 48.6139 124.4C48.6136 124.4 48.6133 124.4 48.6129 124.4L36.1022 124.4V88.4878V88.1275H35.7419H0.438388V11.6801V11.6801C0.438213 10.251 0.722368 8.83609 1.2743 7.51786C1.82623 6.19962 2.63491 5.00436 3.65325 4.00169C4.67158 2.99902 5.87923 2.20897 7.20586 1.67754C8.53249 1.14611 9.9516 0.883933 11.3805 0.906263ZM36.3631 59.3536H36.3632V59.3478V24.2929V23.9327H36.0029H25.8257C25.3733 23.9327 24.9101 24.0572 24.5494 24.2976C24.1852 24.5404 23.8997 24.9236 23.8997 25.4237V64.7409V65.1012H24.2599H30.5228C32.0558 65.1014 33.5274 64.4988 34.6201 63.4235C35.7127 62.3482 36.3388 60.8864 36.3631 59.3536Z" stroke="#E2E2E2" strokeWidth="0.720525"/>
        </svg>
        <svg 
          width="60" 
          height="125" 
          viewBox="0 0 60 125" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
          className={`
            ${index % 2 && styles.outline} ${index % 2 && 'cursor-pointer'} -mt-[10px] -ml-[30px]
            ${openFlight && index % 2 ? `translate-y-[60%] duration-300 ${styles.fill}` : `translate-y-0 duration-300 ${styles.empty}`}
          `}
          onClick={() => handleOpenFlight(1)}
        >
          <path d="M23.6489 36.7288V37.089H24.0092H38.7966H59.2258V113.797C59.2258 116.632 58.0998 119.35 56.0957 121.354C54.0915 123.358 51.3733 124.484 48.5389 124.484H0.361627V11.7641H0.361671L0.361583 11.7585C0.339321 10.3366 0.598769 8.92435 1.12496 7.60323C1.65116 6.2821 2.43369 5.07822 3.42743 4.06101C4.42117 3.04379 5.60646 2.23337 6.91494 1.67648C8.223 1.11977 9.6283 0.82743 11.0498 0.816317H23.6489V36.7288ZM23.562 100.924V101.284H23.9222H34.0994C34.5518 101.284 35.015 101.159 35.3757 100.919C35.7399 100.676 36.0254 100.293 36.0254 99.7928V60.7366V60.3763H35.6652H29.4023C27.8693 60.3761 26.3977 60.9787 25.305 62.054C24.2124 63.1293 23.5863 64.5911 23.562 66.1239V66.1297V100.924Z" stroke="#E2E2E2" strokeWidth="0.720525"/>
        </svg>
      </div>
      <Image loading='lazy' src='/roadmap-right.svg' width={60} height={124} alt='roadmap element' className='-ml-[34%]' />
    </div>
  )
}
